import { Login } from "./components/Login";

import {Routes, Route} from 'react-router-dom';

import { RequireAuth } from './auth/RequireAuth';
import { AccountWorker } from "./components/AccountWorker";
import { AccountAdmin } from "./components/AccountAdmin";
import { useLogin } from "./hook/useLogin";
import { Notfound } from "./components/Notfound";
import { useEffect } from "react";
import useAxios from 'hook/useAxios';

function App() {

  const {user,signOut,setUser}=useLogin();
  const axios = useAxios(user);

  useEffect(()=>{
    if(user){
      axios.get('/enter/',{params:{token:user.token}})
      .then(({data})=>{
          if(data.res === null){
              setUser(null);
          }
      })
    }
  },[])

  if(!(user && [0,1].includes(user?.type))){
    signOut();
  }

  return (
    <Routes>
      <Route path="/login" element={<Login/>}/>
      <Route path="/*" element ={
        <RequireAuth>
          {
            {
              0:<AccountWorker/>,
              1:<AccountAdmin/>
            }[user?.type]
          }
        </RequireAuth>
      }/>
      <Route path="*" element={<Notfound/>}/>
    </Routes>
  );
}

export default App;

import "./Login.scss";

import { useState } from "react";
import {Controller, useForm} from 'react-hook-form';

import { useLogin } from "hook/useLogin";
import useAxios from 'hook/useAxios';

import { Errors } from 'components/Errors';

const Login = () => {

    // got to account
  const [showPas,setPas]=useState(true);
  const {user,signIn} = useLogin();
  const axios=useAxios(user);

  const err = {
    req: "Потрібно заповнити",
    email: "Неправильний формат e-mail",
  }

  // useForm
  const {
    register, 
    handleSubmit,
    formState: {errors},
    clearErrors,
    setError,
    control
  } = useForm({
      defaultValues:{
          login:'',
          pas:'',
          save:false
      }
  });

  const showError = (error) => {
    for (const [key,val] of Object.entries(error)){
        setError(key,{type:"required",message:val})
    }
  }

  // enter user
  const onSubmit = data => {

      axios.get('/enter/',{params:data})
      .then(({data}) => {

          // no user, user problem
          if(data.error){
              showError(data.error);
          }else{
              if(data.res){
                  signIn(data.res);
              }
          }
      })
  }

  const regex = {
    login: /^[^\s]+@[^\s@]+(\.[^\s@]+){1,}$/,
    // pas : /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&.,]).{8,}$/
  }

  return (
    <div className="w-100 h-100 d-flex align-items-center justify-content-center">  
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex flex-column gap-3 form align-items-center" onClick={()=>clearErrors()}>
              <div>
                  <img src="img/logo.jpg" alt="" />
              </div>
              <div className="form-title">XLIB&COFFEE</div>
              <div>
                  <input
                    className="inputs"
                    {...register("login",{
                      required:err.req,
                      validate:value => {
                          return (
                              regex.login.test(value) || Array('admin').includes(value)
                          ) || err.email
                      }
                    })}
                    placeholder="email@gmail.com"
                    autoComplete="new-password"
                  />
                  <Errors error={errors?.login?.message}/>
              </div>
              <div className="position-relative">
                  <input
                    className="inputs"
                    {...register("pas",{
                        required:err.req
                    })}
                    type={showPas?'password':'text'}
                    autoComplete="new-password"
                  />
                  <div className="icons _eye" onClick={()=>setPas(!showPas)}></div>
                  <Errors error={errors?.pas?.message}/>
              </div>
              <div className="d-flex gap-2 w-100">
                  <Controller 
                      name="save"
                      control={control}
                      render={({field:{onChange,value}})=>
                        <>
                          <input
                            id="save"
                            type="checkbox"
                            className="checkbox"
                            checked={value?'checked':''}
                            readOnly
                            />
                            <label htmlFor="save" className="label" onClick={()=>onChange(!value)}>
                              Запамятати вхід
                            </label>
                          </>
                      }
                  />
              </div>
              <div className="w-100">
                  <button type="submit" className="button w-100">Вхід</button>
              </div>
          </div>
        </form>
    </div>
  );
};

export { Login };

import useAxios from "../hook/useAxios";
import {createContext,useState} from "react";
import {useNavigate} from 'react-router-dom';

export const AuthContext = createContext();

export const AuthProvider = ({children}) => {

    const [user,setUser] = useState(
        localStorage.getItem('user')
        ?JSON.parse(localStorage.getItem('user'))
        :null
    );

    const axios = useAxios(user);
    const navigate = useNavigate();

    const positionDef = {
        selected:{},
        list:[]
    }
    const [position,setPosition] = useState(
        localStorage.getItem('position')
        ?JSON.parse(localStorage.getItem('position'))
        :positionDef
    );

    const setPositionDef = () =>{
        setPosition(positionDef);
    }

    const buildDef = {
        selected:{},
        list:[]
    }

    const [build,setBuild] = useState(
        localStorage.getItem('build')
        ?JSON.parse(localStorage.getItem('build'))
        :buildDef
    );

    const setBuildDef = () =>{
        setBuild(buildDef);
        setPositionDef();
    }

    const signIn = user => {

        // set user
        localStorage.setItem('user',JSON.stringify(user));
        setUser(user);
        navigate('/',{replace:true})

    }

    const signOut = () => {

        // set user
        localStorage.removeItem('user');
        setUser(null);
        // navigate('/',{replace:true})

    }

    const selectBuild= (id) =>{
        const newBuild = {
            ...build,
            selected:build.list.filter(item=>item.id == id)[0],
        };
        setBuild(newBuild);
    }

    const selectPosition= (id) =>{
        const newPosition = {
            ...position,
            selected:position.list.filter(item=>item.id == id)[0]
        }
        setPosition(newPosition);
    }

    const value = {

        user,
        setUser,
        signIn,
        signOut,

        build,
        setBuild,
        selectBuild,
        setBuildDef,

        position,
        setPosition,
        setPositionDef,
        selectPosition

    }

    return <AuthContext.Provider value={value}>
        {children}
    </AuthContext.Provider>
}
import useAxios from 'hook/useAxios';
import { useLogin } from 'hook/useLogin';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ToastContainer, toast} from 'react-toastify';

import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";

const Message = () => {

    const {user} = useLogin();
    const axios=useAxios(user);

    const [list,setList] = useState([]);

    useEffect(()=>{
        const reqUrl = `/dashboard/request`;
        const fetchData = () => {
            axios.get(reqUrl)
            .then(({data}) => {
                setList(data.res);
            })
        }

        const intervalId = setInterval(() => fetchData(),60000);
        fetchData();
        return () => clearInterval(intervalId);
    },[]);

    // const goToDate = id =>{}

    // const agree = id =>{
    //     const newList = list.filter(item => item.id_time != id);
    //     setList(newList)
    // }

    const cancel = id => {
        console.log(list,id);

        const reqUrl = `/dashboard/request`;
        const reqData = {id:id,cancel:true}
        axios.delete(reqUrl,{params:reqData})
        .then(({data}) => {
            if(data.error){
                toast.error(data.error);
            }else{
                toast.success(data.res);
                del(id);
            }
        })
    }

    const del = id => {
        const newList = list.filter(item => item.id != id);
        console.log(newList);
        setList(newList);
    }

    return (
        list.length?
        <Panel className='overflow-none'>
            <Swiper
                spaceBetween={20}
                navigation
                slidesPerView={3}
                slidesPerGroup={1}
                scrollbar={{ draggable: true }}
                breakpoints={{
                    320: {
                      slidesPerView: 1,
                    },
                    640: {
                      slidesPerView: 2,
                    },
                    1024: {
                      slidesPerView: 3,
                    },
                  }}
                >
                {list.map((item,key)=>
                <SwiperSlide key={key} style={{maxWidth:'280px'}}>
                    <Warning  className='p-3 d-flex gap-3'>
                        <div>
                            <div><small>Створено {item.date}</small></div>
                            <div className="fw-bold">Скасування: {item.name}</div>
                            <div><s>{item.cdate}</s></div>
                        </div>
                        <div className="d-flex align-items-center gap-3">
                            <div className="icons _agree" onClick={()=>cancel(item.id)}></div>
                            <div className="icons _delete" onClick={()=>cancel(item.id)}></div>
                        </div>
                    </Warning>
                </SwiperSlide>
                )}
            </Swiper>
            <ToastContainer 
                position="bottom-right"
                theme="light"
                pauseOnFocusLoss={false}
                pauseOnHover={false}
                hideProgressBar={true}
                newestOnTop={true}
                autoClose={3000}
            />
        </Panel>:''
    )
}

const Panel = styled.div`
    border-radius:20px;
    background:#fff;
    box-shadow:0 0 20px rgba(0,0,0,.2);
    padding:20px;
    overflow:hidden;
    flex:0 0 127px;
`

const Warning = styled.div`
    border-left:5px solid var(--red);
    background:var(--yellow);
`

export {Message}
import { ToastContainer } from "react-toastify";
import Calendar from "./AccountWorker/Calendar"

// import { useState } from "react"
// import Modal from "./Modal"
import { useLogin } from "hook/useLogin"
import { MenuAccount } from "./MenuAccount";
import { Link } from "react-router-dom";

const AccountWorker = () =>{

    const {signOut} = useLogin();

    return (
        <div className="main">
            <div className="d-flex justify-content-between align-items-center">
                <Link to="/"><h1>XLIB&COFFEE</h1></Link>
                <div className="d-flex gap-3">
                    <MenuAccount/>
                    <div className="icons _exit" onClick={()=>signOut()}></div>
                </div>
            </div>
            <Calendar/>
            <ToastContainer 
                position="bottom-right"
                theme="light"
                pauseOnFocusLoss={false}
                pauseOnHover={false}
                hideProgressBar={true}
                newestOnTop={true}
                autoClose={3000}
            />
        </div>
    )
}

export {AccountWorker}
import React, { useEffect, useState } from 'react';
import ReactDOM from "react-dom";

const Modal = ({ isOpen, onClose, children }) => {
  
  const [animate,setAnimate] = useState(false)

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      window.addEventListener('keydown', handleKeyDown);
    }

    setAnimate(true);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, onClose]);

  // Закриття модального вікна при кліку на затемнення
  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  // Якщо модальне вікно закрите, нічого не рендеримо
  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className='modal-overlay' onClick={handleOverlayClick}>
      <div className={`modal-container ${animate && '_show'}`}>
        <div className='modal-close' onClick={()=>onClose()}/>
        {children}
      </div>
    </div>,
    document.getElementById("modal-root") 
  );
};

export default Modal;

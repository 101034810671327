
import React, {useEffect, useRef, useState } from 'react';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import ukLocale from '@fullcalendar/core/locales/uk';

import styled from 'styled-components';
import {Spinner} from 'react-bootstrap';

import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useLogin } from 'hook/useLogin';
import useAxios from 'hook/useAxios';

import { ModalAdd } from './ModalAdd';

// events: [
//   {id:1, title: 'Влад', start: '2024-10-31T08:00:00', end: '2024-10-31T18:00:00', classNames: '_yel'},
//   {id:2, title: 'Оксана', start: '2024-10-30T08:00:00', end: '2024-10-30T14:00:00', classNames: '_red'}
// ]

const Calendar = () => {

  const {user,position} = useLogin();
  const axios=useAxios(user);

  const [events,setEvents] = useState([]);
  const [calendarKey, setCalendarKey] = useState(0);
  const calendarRef = useRef(null);

  const [loading, setLoading] = useState(false);

  const [modal, setModal] = useState({
    show:false,
    event:null
  });

  // mobile
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth < 768;
      if (mobile !== isMobile) {
        setIsMobile(mobile);
        setCalendarKey(prevKey => prevKey + 1);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isMobile]);
  const initialView = isMobile ? "listWeek" : 'timeGridWeek'
  const headerToolbar = isMobile
    ? { left: 'prev,next', center: 'title', right: '' }
    : { left: 'today,prev,next', center: 'title', right: 'timeGridWeek'} // dayGridMonth

  //* create event
  const clickTimeout = useRef(null);
  const handleDateClick = (info) => {
    if (clickTimeout.current) {
      
      clearTimeout(clickTimeout.current);
      clickTimeout.current = null;

      const startDate = new Date(info.date);
      const endDate = new Date(startDate);
      endDate.setHours(startDate.getHours() + 1);

      const isOverlap = events.some(event => 
        (startDate < new Date(event.end) && endDate > new Date(event.start))
      );
      
      if (!isOverlap) {
        
        const reqData = {
          start:startDate,
          end:endDate,
          id_position:position.selected.id
        }

        const reqUrl = `/dashboard/time`;
        axios
        .post(reqUrl,reqData)
        .then(({data}) => {
            if(data.res){
              setEvents([
                ...events,
                {
                  id:data.res.id,
                  title:'',
                  start: startDate,
                  end: endDate,
                  classNames: ''
                },
              ]);
            }else{
              toast.error("Помилка запиту");
            }
        })
        .catch((err) => {
            toast.error("Помилка сервера");
            console.error("Error:", err);
        });
      }else{
        toast.error("Накладання неможливе");
      }
    } else {
      clickTimeout.current = setTimeout(() => {
        clickTimeout.current = null;
      }, 300);
    }
  };

  //* move event
  const handleEventChange = (info) => {
    const { start, end, id } = info.event;
    const intId = +id; // int val
    
    const isOverlap = events.some(event => 
      event.id != intId && start < new Date(event.end) && end > new Date(event.start)
    );

    if (isOverlap) {
      info.revert();
      toast.error("Накладання неможливе");
    } else {
      // setLoading(true);
      const reqUrl = `/dashboard/time`;
      const reqData = {id:id,start,end};
      axios
      .post(reqUrl,reqData)
      .then(({data}) => {
          if(data.res){
            const updatedEvents = events.map(event =>
              event.id === intId ? { ...event, start, end } : event
            );
            setEvents(updatedEvents);
          }else{
            toast.error("Помилка сервера");
          }
          // setLoading(false);
      })
      .catch((err) => {
          toast.error("Помилка сервера");
          console.error("Error:", err);
      });
    }
  };

  //* user choise
  const selectUser = (data) => {
    const {id,user} = data;
    const idInt = +id;

    // request
    setLoading(true);
    const reqUrl = `/dashboard/link`;
    const reqData = {id_time:idInt,id_user:user.id};
    axios
    .post(reqUrl,reqData)
    .then(({data}) => {

        const updatedEvents = events.map(event =>
          event.id === idInt 
          ? { 
            ...event, 
            classNames: data.res.className,
            user: data.res.user } 
          : event
        );
        setEvents(updatedEvents);
        setLoading(false);
    })
    .catch((err) => {
        toast.error("Помилка сервера");
        console.error("Error:", err);
    });
  }

  //* delete event
  const delEvent = id => {
    const updatedEvents = events.filter(item=>item.id != id);
    setEvents(updatedEvents);

    // request
    setLoading(true);
    const reqUrl = `/dashboard/time`;
    axios
    .delete(reqUrl,{params:{id:id}})
    .then(() => {
        setEvents(updatedEvents);
        setLoading(false);
    })
    .catch((err) => {
        toast.error("Помилка сервера");
        console.error("Error:", err);
    });
  }

  //* event click open modal
  const handleEventClick = (info) => {
    setModal({show:true,event:info.event});
  };

  // 
  // const handleCloseModal = () => {
  //   setShowModal(false);
  //   setSelectedEvent(null);
  // };

  // const handleSaveChanges = () => {
  //   if (selectedEvent) {
  //     selectedEvent.setProp('title', title); // Оновлення заголовка події
  //   }
  //   handleCloseModal();
  // };

  //* resize event
  // const handleEventResize = (info) => {
  //   const { start, end, id } = info.event;
  //   const intId = +id;

  //   const isOverlap = events.some(event =>
  //     event.id !== intId && start < new Date(event.end) && end > new Date(event.start)
  //   );

  //   if (isOverlap) {
  //     toast.error("Накладання не можливе");
  //     info.revert();
  //   } else {
  //     const updatedEvents = events.map(event =>
  //       event.id === id ? { ...event, start, end } : event
  //     );
  //     setEvents(updatedEvents);
  //   }
  // };

  // const handleWindowResize = () => {
  //   const calendarApi = calendarRef.current.getApi();
  //   if (window.innerWidth < 768) {
  //     calendarApi.changeView('listWeek'); // Вертикальний вигляд на мобільних
  //   } else {
  //     calendarApi.changeView('dayGridMonth'); // Стандартний вигляд для десктопу
  //   }
  // };

  const refreshCalendar = () =>{
    const reqData = {id:position.selected.id}
    const reqUrl = `/dashboard/time`;

    axios
    .get(reqUrl,{params:reqData})
    .then(({data}) => {
        if(data.res){
            setEvents(data.res)
            setLoading(false);
        }
    })
    .catch((err) => {
        console.error("Error:", err);
    });
  }

  useEffect(()=>{
    setLoading(true);
    refreshCalendar();
  },[position])

//   const colors = [
//     {'_yel':['#F9C335','#FFF5D8']},
//     {'_blue':['#31A5DB','#BDE2F4']},
//     {'_green':['#95E198','#DEF6DF']},
//     {'_purple':['#CB77D9','#EFD6F4']},
//     {'_red':['#D97777','#F4D6D6']}
//   ]

//   const setColor = (color) => {
//     const foundColor = colors.find(item => item[color]);
//     if (foundColor) {return foundColor[color]}
//     return colors['_yel'];
//   }

  const renderEventContent = (info) => {
    const isTimeGridView = info.view.type === 'timeGridWeek' || info.view.type === 'timeGridDay'; // Перевірка вигляду
    if (isTimeGridView) { 
        const user = info.event.extendedProps.user;
        const startTime = info.event.start.toLocaleTimeString([], { 
          hour: '2-digit', 
          minute: '2-digit',
          // timeZone: 'Europe/Kyiv'
        });
        const endTime = info.event.end.toLocaleTimeString([], { 
          hour: '2-digit', 
          minute: '2-digit',
          // timeZone: 'Europe/Kyiv'
        });
        return (
            <div>
                <div>{startTime} - {endTime}</div>
                {user ? <div><strong>{user.name}</strong></div> : ''}
            </div>
        );
    }else{
        const user = info.event.extendedProps.user;
        return (
            <div>
                {user ? <div><strong>{user.name}</strong></div> : ''}
            </div>
        );
    }
  };

  return (
    <div className='main-block'>
      {loading && (
        <div
          style={{
            position: 'fixed',
            inset:0,
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            zIndex: 1050,
            display:'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner animation="border" variant="dark" />
        </div>
      )}
      <FullCalendar
        key={calendarKey}
        ref={calendarRef}
        selectable={false}
        editable={true}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
        locales={[ukLocale]}
        locale="uk"
        // timeZone="Europe/Kiev"
        initialView={initialView}
        slotMinTime="08:00:00"
        slotMaxTime="22:00:00"
        slotLabelFormat={{
            hour: 'numeric',
            minute: '2-digit',
            meridiem: false
        }}
        allDaySlot={false}
        headerToolbar={headerToolbar}
        dayHeaderContent={(args) => {
            // Отримуємо номер дня та повну назву дня
            const dayNumber = args.date.getDate();
            const dayName = args.date.toLocaleDateString('uk-UA', { weekday: 'long' });
  
            return (
              <div className="custom-header">
                <div className="day-number">{dayNumber}</div>
                <div className="day-name">{dayName}</div>
              </div>
            );
          }}
        events={events}
        // windowResize={handleWindowResize}
        eventContent={renderEventContent}
        eventDrop={handleEventChange}
        eventResize={handleEventChange}
        dateClick={handleDateClick}
        eventClick={handleEventClick}
      />

      {modal.event && <ModalAdd modal={modal} setModal={setModal} selectUser={selectUser} delEvent={delEvent}/>}

      {/* <Modal show={showModal} onHide={handleCloseModal} centered={true}>
        <Modal.Header closeButton>
          <Modal.Title>Редагування події</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formEventTitle">
              <Form.Label>Назва події</Form.Label>
              <Form.Control
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Group>
            <SelectInput
              placeholder="Вибрати працівника"
              data={data.route}
              setData={({ label }) => setData({ ...data, route: label })}
              options={route}
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Скасувати
          </Button>
          <Button variant="primary" onClick={handleSaveChanges}>
            Зберегти зміни
          </Button>
        </Modal.Footer>
      </Modal> */}
    </div>
  );

};

export default Calendar;
// hook for axious request

import axios from 'axios';

const useAxios = (user) => {

    const config={
        baseURL:`${process.env.REACT_APP_API}`,
        headers:
            {
                'Authorization':`Bearer ${user?.token?user.token:''}`,
                'Content-Type' : 'multipart/form-data'
            }
    };

    const instance = axios.create(config);

    instance.interceptors.response.use(response => {
        return response;
    }, error => {
        if(error.code === "ERR_NETWORK") {
            console.error(error.message);
        }
        return Promise.reject(error);
    });

    return instance;

}

export default useAxios;


// axios.defaults.baseURL = process.env.REACT_APP_API;
// 1?axios.defaults.headers.common['Authorization'] =  `Bearer ${process.env.REACT_APP_TEST_TOKEN}`:'';

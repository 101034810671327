import { MenuBuild } from "./Menu/MenuBuild";
import { MenuPosition } from "./Menu/MenuPosition";
import { MenuUsers } from "./Menu/MenuUsers";

import Calendar from "./Calendar";
import { useLogin } from "hook/useLogin";

const Main = () =>{

    const {build,position} = useLogin();

    return (
        <div className="d-flex flex-column flex-grow-1">
            <div className="d-none d-md-flex justify-content-between align-items-center">
                <div className="d-flex gap-5">
                    <MenuBuild/>
                    {build.selected?.id && <MenuPosition/>}
                </div>
                <div>
                    <MenuUsers/>
                </div>
            </div>
            <div className="mt-3 flex-grow-1 d-flex flex-column">
            {position.selected?.id 
                ? <Calendar/> 
                : <div className="calendar-empty">
                    <img src="/img/worker.png"/>
                    <div>Посада не вибрана</div>
                </div>
            }
            </div>
        </div>
    )
}
export {Main}
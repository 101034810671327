const Settings = () =>{
    return (
        <div className='main-block p-5'>
            <h3>Налаштування</h3>
            {/* <div className="d-flex flex-column gap-3 my-3">
                <div><input type="text" className="inputs" placeholder="Ваше ім'я"/></div>
                <div><input type="text" className="inputs" placeholder="Логін"/></div>
                <div><input type="text" className="inputs" placeholder="Пароль"/></div>
                <div><button type="button" className="button">Зберегти</button></div>
            </div> */}
        </div>
    )
}

export {Settings}
import Modal from "components/Modal";

const ModalRequest = ({modal,setModal,sendRequest}) => {

    const event = modal.event;

    const date = event.start.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit',});
    const startTime = event.start.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit'});
    const endTime = event.end.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit'});

    const time = `${date} | ${startTime} - ${endTime}`;

    const send = () =>{
        sendRequest(event.id)
        close();
    }

    const close = () =>{
        setModal({...modal,show:false});
    }

    return (
        <Modal isOpen={modal.show} onClose={()=>close()}>
            <div className="mb-3"><h6>Ви бажаєте відправити запит на відсутність ?</h6></div>
            <div className="fw-bold my-3">{time}</div>
            <div className='d-flex gap-3 align-items-center'>
                <div className="button"  onClick={()=>send()}>Підтвердити</div>
                <div className="button _none" onClick={()=>close()}>Ні</div>
            </div>
        </Modal>
    )
}

export {ModalRequest}
import { useLogin } from "hook/useLogin";
import useAxios from "hook/useAxios";

import { useEffect, useState } from "react";
import { Dropdown } from "../Dropdown";

import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MenuPosition = () =>{

    const {user,setPosition,selectPosition,setPositionDef,position,build} = useLogin();
    const axios=useAxios(user);

    const [show,setShow] = useState(false);
    const [dropType, setDropType] = useState(true);

    const def = {
        name:''
      }

      const [form,setForm] = useState(def);
      const [errors, setErrors] = useState({});
    
      const validate = () => {
        let errors = {};
        
        const requiredFields = ['name'];
        for (let field of requiredFields) {
          if (form[field] === undefined || !form[field].trim()) {
            errors[field] = `Поле не може бути пустим`;
          }
        }
    
        setErrors(errors);
        return Object.keys(errors).length === 0;
      };
    
      const sendForm = () => {
        if (validate()) {
          const reqData = form.id? form : {...form,id_build:build.selected.id}
          const reqUrl = `/dashboard/position`;
    
          axios
            .post(reqUrl, reqData)
            .then(({data}) => {
              setForm(def);
              setShow(false);
              if(data.res){
                setPosition(data);
                renewPosition();
              }
            })
            .catch((err) => {
              console.error("Error:", err);
            });
        }
      };
    
      const renewPosition = () =>{
        const reqUrl = `/dashboard/position`;
        const reqData = {
            id:build.selected.id
        }
    
        axios
        .get(reqUrl,{params:reqData})
        .then(({data}) => {
            if(data.res){
              if(data.res.length){
                  const selected = position.selected?.id 
                      ? position.selected 
                      : data.res[0];
                  const newPosition = {
                      selected,
                      list:data.res
                  }
                  setPosition(newPosition);
              }else{
                setPositionDef();
              }
            }
        })
        .catch((err) => {
            console.error("Error:", err);
        });
      }
    
      const changeForm = (e) =>{
        setErrors({});
        form[e.target.name] = e.target.value;
        setForm({...form});
      }
    
      const showDrop = type =>{
        setDropType(type);
        setShow((prev) => !prev);
      }

      const select = id =>{
        selectPosition(id);
        setShow(false);
      }

      const delForm = (id) =>{
        const reqUrl = `/dashboard/position`;

        axios
        .delete(reqUrl,{params:{id:id}})
        .then(() => {
            renewPosition();
            setShow(false);
            setPositionDef();
            toast.info("Посаду видалено")
          }
        )
        .catch((err) => {
            console.error("Error:", err);
        });
        
      }

      const editForm = (e,id) =>{
        e.stopPropagation();
        const positionData = position.list.filter(item => item.id == id)[0];
        setErrors({});
        setForm(positionData);
        setDropType(true);
      }

      const addForm = () =>{
        setForm(def);
        showDrop(true);
      }
    
      useEffect(() => {
        renewPosition();
      },[build])
    
    return (
        <div className="drop-menu">
            {position.selected?.id
                ?<span className="drop-title" onClick={() => showDrop(false)}>{position.selected.name}</span>
                :<span className="drop-title opacity-50" onClick={() => addForm()}>Посада</span>
            }
            <div className="drop-plus" onClick={() => addForm()}></div>
            {show && 
                <Dropdown setShow={setShow}>
                    {dropType
                    ?<>
                        {form.id
                        ?<h6 className="mb-4">Змінити посаду</h6>
                        :<h6 className="mb-4">Додати посаду</h6>}
                        <div className="d-flex gap-3 flex-column">
                            <div>
                                <label className="inputs-label">Назва посади</label>
                                <input 
                                    value={form.name}
                                    onChange={changeForm}
                                    className="inputs" 
                                    name="name"
                                    placeholder="" />
                                    {errors.name && <div className='error mt-1'>{errors.name}</div>}
                            </div>
                            <div
                                className="button w-100"
                                onClick={() => sendForm()}>
                                {form.id ? 'Змінити' : 'Додати'}
                            </div>
                            {form.id && <div className="button _none text-center" onClick={() => delForm(form.id)}>Видалити</div>}
                        </div>
                    </>
                    :<>
                        <h6 className="mb-3">Посада</h6>
                        <div className="d-flex gap-1 flex-column drop-list">
                        {position.list &&
                            position.list.map((item,key)=>{
                                const checked = position.selected.id === item.id ? '_checked' : '';
                                return <div 
                                    key={key} 
                                    className={`drop-item ${checked}`} 
                                    onClick={()=>select(item.id)}>
                                    <div className="text-truncate">{item.name}</div>
                                    <div className="drop-edit" onClick={(e)=>editForm(e,item.id)}></div>
                                </div>
                            })
                        }
                        </div>
                    </>}
                </Dropdown>
            }
        </div>
    )
}

export {MenuPosition}
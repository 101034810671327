import { useLogin } from "hook/useLogin";
import useAxios from "hook/useAxios";

import { Dropdown } from "../Dropdown";
import { useEffect, useState } from "react";

import { toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const color = [
    '#F9C335','#239FD9','#90E093','#CB77D9','#D97777'
];

const MenuUsers = () =>{

    const {user,signIn} = useLogin();
    const axios=useAxios(user);

    const [show,setShow] = useState(false);
    const [showPas,setPas]=useState(false);
    const [dropType, setDropType] = useState(true);

    const [users,setUsers] = useState([]); 

      const def = {
        id:null,
        name: "",
        rate:0,
        login: "",
        pas: "",
        color: "#F9C335"
      }

      const [form,setForm] = useState(def);
      const [errors, setErrors] = useState({});
    
      const validate = () => {
        let errors = {};
        
        const requiredFields = ['name','login','pas'];
        for (let field of requiredFields) {
          if (form[field] === undefined || !form[field].toString().trim()) {
            errors[field] = `Поле не може бути пустим`;
          }

          if (!form.admin && !/\S+@\S+\.\S+/.test(form.login)) {
            errors.login = "Email неправильний";
          }
        }
    
        setErrors(errors);
        return Object.keys(errors).length === 0;
      };

      const setColor = (color) =>{
        setForm({
            ...form,
            color:color
        })
      }
    
      const sendForm = () => {
        if (validate()) {
          const reqData = form;
          const reqUrl = `/dashboard/user`;
    
          axios
            .post(reqUrl, reqData)
            .then(({data}) => {
                if(!data.error){
                  setForm(def);
                  setShow(false);
                  renewUser();

                  reqData.id 
                    ?toast.success("Дані змінені")
                    :toast.success("Користувача дадано");
                  
                  // renew for admin
                  if(reqData.id === user.id){
                    signIn({...user,...reqData});
                  }
                  // add to list
                }else{
                  setErrors({login:"Цей логін занятий"});
                }
            })
            .catch((err) => {
              console.error("Error:", err);
            });
        }
      };

      const delForm = (id) =>{
        const reqUrl = `/dashboard/user`;

        axios
        .delete(reqUrl,{params:{id:id}})
        .then(() => {
            renewUser();
            setShow(false);
            toast.info("Користувача видалено")
          }
        )
        .catch((err) => {
            console.error("Error:", err);
        });
        
      }

      const editForm = (id) =>{
        const userData = users.filter(item => item.id == id)[0];
        setErrors({});
        setForm(userData);
        setDropType(true);
      }

      const addForm = () =>{
        setForm(def);
        showDrop(true);
      }
    
      useEffect(() => {
        renewUser();
      },[])

      const justNumber = (e) => {
        const val = e.target.value;
        const name = e.target.name;
        
        if (/^\d*\.?\d*$/.test(val)) {
            setForm({...form,[name]:val});
        }
      };
    
      const changeForm = (e) =>{
        setErrors({});
        form[e.target.name] = e.target.value;
        setForm({...form});
      }

      const showDrop = type =>{
        setDropType(type);
        setShow(true);
      }

      const renewUser = () =>{
        const reqUrl = `/dashboard/user`;

        axios
        .get(reqUrl)
        .then(({data}) => {
            const usersArr = data.res.filter(item => item.admin != 1);
            setUsers(usersArr);
        })
        .catch((err) => {
            console.error("Error:", err);
        });
      }

    return (
        <div className="drop-menu">
            <span className="drop-title" onClick={()=>users.length ? showDrop(false) : addForm()}>Працівники</span> 
            <div className="drop-plus" onClick={()=>addForm()}></div>
            {show
                && <Dropdown right={true} setShow={setShow}>
                {dropType
                    ?<>
                        {form.id
                          ?<h6 className="mb-4">Зміна працівника</h6>
                          :<h6 className="mb-4">Додати працівника</h6>
                        }
                        <form className="d-flex gap-3 flex-column">
                            <div>
                                <label className="inputs-label">Ім'я користувача</label>
                                <input className="inputs" name="name" placeholder="" value={form.name} onChange={changeForm}/>
                                {errors.name && <div className='error mt-1'>{errors.name}</div>}
                            </div>
                            <div>
                                <label className="inputs-label">Ставка</label>
                                <input className="inputs" name="rate" placeholder="" value={form.rate} onChange={justNumber}/>
                                {errors.rate && <div className='error mt-1'>{errors.rate}</div>}
                            </div>
                            <div>
                                <label className="inputs-label">Логін</label>
                                <input className="inputs" name="login" placeholder="" disabled={form.id == 1} value={form.login} onChange={changeForm} autoComplete="new-password"/>
                                {errors.login && <div className='error mt-1'>{errors.login}</div>}
                            </div>
                            <div className="position-relative">
                                <label className="inputs-label">Пароль</label>
                                <input className="inputs" name="pas" placeholder="" value={form.pas} onChange={changeForm} type={showPas?'password':'text'} autoComplete="new-password"/>
                                <div className="icons _eye" onClick={()=>setPas(!showPas)}></div>
                                {errors.pas && <div className='error mt-1'>{errors.pas}</div>}
                            </div>
                            <div>
                              <div className="drop-color">
                                  {color.map((color,key)=>{
                                      const checked = color === form.color ? '_checked' : '';
                                      return <div key={key} className={`drop-color-item ${checked}`} style={{'--color':`${color}`}} onClick={()=>setColor(color)}></div>
                                  })}
                              </div>
                            </div>
                            <div 
                                className="button w-100"
                                onClick={() => sendForm(true)}>
                                {form.id ? 'Змінити' : 'Додати'}
                            </div>
                            {form.id && !form.admin && <div className="button _none text-center" onClick={() => delForm(form.id)}>Видалити</div>}
                        </form>
                    </>
                    :<>
                        <h5 className="mb-3">Користувачі</h5>
                        <div className="d-flex gap-1 flex-column drop-list">
                        {
                            users.map((item,key)=>{
                                return <div 
                                    key={key}
                                    className={`drop-item _nocheck`}>
                                    <div className="text-truncate">{item.name}</div>
                                    <div className="drop-edit" onClick={()=>editForm(item.id)}></div>
                                </div>
                            })
                        }
                        </div>
                    </>
                }
            </Dropdown>
            }
        </div>
    )
}

export {MenuUsers}
import { useLogin } from "hook/useLogin";
import useAxios from "hook/useAxios";

import { Dropdown } from "./AccountAdmin/Dropdown";
import { useEffect, useState } from "react";

import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MenuAccount = () =>{

    const {user,signIn} = useLogin();
    const axios=useAxios(user);

    const [show,setShow] = useState(false);
    const [showPas,setPas]=useState(true);

      const [form,setForm] = useState(null);
      const [errors, setErrors] = useState({});
    
      const validate = () => {
        let errors = {};
        
        const requiredFields = ['name','login','pas'];
        for (let field of requiredFields) {
          if (form[field] === undefined || !form[field].toString().trim()) {
            errors[field] = `Поле не може бути пустим`;
          }

          if (!form.admin && !/\S+@\S+\.\S+/.test(form.login)) {
            errors.login = "Email неправильний";
          }
        }
    
        setErrors(errors);
        return Object.keys(errors).length === 0;
      };
    
      const sendForm = () => {
        if (validate()) {
          const reqData = form;
          const reqUrl = `/dashboard/user`;
    
          axios
            .post(reqUrl, reqData)
            .then(({data}) => {
                if(!data.error){
                  setShow(false);
                  toast.success("Дані змінені");
                  signIn({...user,...reqData});
                }else{
                  setErrors({login:"Цей логін занятий"});
                }
            })
            .catch((err) => {
              console.error("Error:", err);
            });
        }
      };
    
      useEffect(() => {
        renewUser();
      },[])
    
      const changeForm = (e) =>{
        setErrors({});
        form[e.target.name] = e.target.value;
        setForm({...form});
      }


      const renewUser = () =>{
        const reqUrl = `/dashboard/user`;

        axios
        .get(reqUrl,{params:{id:user.id}})
        .then(({data}) =>
            setForm(data.res[0])
        )
        .catch((err) => {
            console.error("Error:", err);
        });
      }

    return (
        <div style={{position:"relative"}}>
            <div to="/account/user" className="icons _user" onClick={()=>setShow(true)}></div>
            {show && form
                ?<Dropdown right={true} setShow={setShow}>
                    <h5 className="mb-3">Налаштування</h5>
                    <div className="d-flex gap-3 flex-column">
                        <div>
                            <label className="inputs-label">Ваше ім'я</label>
                            <input className="inputs" name="name" placeholder="" value={form.name} onChange={changeForm}/>
                            {errors.name && <div className='error mt-1'>{errors.name}</div>}
                        </div>
                        <div>
                            <label className="inputs-label">Логін</label>
                            <input className="inputs" name="login" placeholder="" disabled={form.admin?true:false} value={form.login} onChange={changeForm}/>
                            {errors.login && <div className='error mt-1'>{errors.login}</div>}
                        </div>
                        <div className="position-relative">
                            <label className="inputs-label">Пароль</label>
                            <input className="inputs" name="pas" placeholder="" value={form.pas} onChange={changeForm} type={showPas?'password':'text'} autoComplete="new-password"/>
                            <div className="icons _eye" onClick={()=>setPas(!showPas)}></div>
                            {errors.pas && <div className='error mt-1'>{errors.pas}</div>}
                        </div>
                        <div 
                            className="button w-100"
                            onClick={() => sendForm(true)}>
                            Змінити
                        </div>
                    </div>
            </Dropdown>:''}
        </div>
    )
}

export {MenuAccount}
import { Message } from "./AccountAdmin/Message"
import { useLogin } from "hook/useLogin"
import { Link, Route, Routes } from "react-router-dom"
import { Main } from "./AccountAdmin/Main"
import { Account, Settings } from "./AccountAdmin/Settings"
import { MenuAccount } from "./MenuAccount"
import { ToastContainer } from "react-toastify"

const AccountAdmin = () =>{

    const {signOut} = useLogin();

    return (
        <div className="main">
            <div className="d-flex justify-content-between align-items-center">
                <Link to="/"><h1>XLIB&COFFEE</h1></Link>
                <div className="d-flex gap-3">
                    <MenuAccount/>
                    <div className="icons _exit" onClick={()=>signOut()}></div>
                </div>
            </div>
            <Message/>
            <Routes>
                <Route path="/account/user" element={<Settings/>}></Route>
                <Route path="*" element={<Main/>}></Route>
            </Routes>
            <ToastContainer 
                position="bottom-right"
                theme="light"
                pauseOnFocusLoss={false}
                pauseOnHover={false}
                hideProgressBar={true}
                newestOnTop={true}
                autoClose={3000}
            />
        </div>
    )
}

export {AccountAdmin}
import {Navigate} from 'react-router-dom';
import {useLogin} from '../hook/useLogin';

const RequireAuth = ({children}) => {

    const {user} = useLogin();

    // if not auth return relink component
    if(!user){
        return <Navigate to='/login'/>
    }

    // else return private page
    return children;
} 

export {RequireAuth}

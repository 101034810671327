import { useEffect, useRef, useState } from "react"

const Dropdown = ({children,right,setShow}) =>{

    const [animate,setAnimate] = useState(false)

    const dropdownRef = useRef(null);
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShow(false);
        }
    };

    useEffect(()=>{
        setAnimate(true);
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    },[])

    return (
        <div className={`drop-block ${animate?'_show':''} ${right?'_right':''}`} ref={dropdownRef}>
            <div className="position-relative pb-4">
                {children}
                <div className="drop-close" onClick={()=>setShow(false)}/>
            </div>
        </div>
    )
}

export {Dropdown}
import { Dropdown } from "../Dropdown";

import { useLogin } from "hook/useLogin";
import useAxios from "hook/useAxios";
import { useEffect, useState } from "react";

import { toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MenuBuild = () => {

  const {user,setBuild,selectBuild,setBuildDef,build} = useLogin();
  const axios=useAxios(user);

  const [show, setShow] = useState(false);
  const [dropType, setDropType] = useState(true);

  const def = {
    name:''
  }

  const [form,setForm] = useState(def);
  const [errors, setErrors] = useState({});

  const validate = () => {
    let errors = {};
    
    const requiredFields = ['name'];
    for (let field of requiredFields) {
      if (form[field] === undefined || !form[field].toString().trim()) {
        errors[field] = `Поле не може бути пустим`;
      }
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const sendForm = () => {
    if (validate()) {
      const reqData = form;
      const reqUrl = `/dashboard/build`;

      axios
        .post(reqUrl, reqData)
        .then(({data}) => {
          setForm(def);
          setShow(false);
          if(data.res){
            if(reqData.id){
              toast.success("Дані змінені")
            }else{
              setBuild(data);
              toast.success("Заклад дадано");
            }
            renewBuild();
          }
        })
        .catch((err) => {
          console.error("Error:", err);
        });
    }
  };

  const renewBuild = () =>{
    const reqUrl = `/dashboard/build`;

    axios
    .get(reqUrl)
    .then(({data}) => {
        if(data.res){
            if(data.res.length){
              const selected = build.selected?.id 
                  ? build.selected 
                  : data.res[0];
              setBuild({
                  selected,
                  list:data.res
              });
            }else{
              setBuildDef();
            }
        }
    })
    .catch((err) => {
        console.error("Error:", err);
    });
  }

  const changeForm = (e) =>{
    setErrors({});
    form[e.target.name] = e.target.value;
    setForm({...form});
  }

  const showDrop = type =>{
    setDropType(type);
    setShow(true);
  }

  const select = id =>{
    selectBuild(id);
    setShow(false);
  }

  const addForm = () =>{
    setForm(def);
    showDrop(true);
  }

  const editForm = (e,id) =>{
    e.stopPropagation();
    const buildData = build.list.filter(item => item.id == id)[0];
    setErrors({});
    setForm(buildData);
    setDropType(true);
  }

  const delForm = (id) =>{
    const reqUrl = `/dashboard/build`;

    axios
    .delete(reqUrl,{params:{id:id}})
    .then(() => {
        setBuildDef();
        setShow(false);
        renewBuild();
        toast.info("Заклад видалено")
      }
    )
    .catch((err) => {
        console.error("Error:", err);
    });
    
  }

  useEffect(() => {
    renewBuild();
  },[])

  return (
    <div className="drop-menu">
      {build.selected?.id
        ?<span className="drop-title" onClick={() => showDrop(false)}>{build.selected.name}</span>
        :<span className="drop-title opacity-50" onClick={() => addForm(true)}>Заклад</span>
      }
    
      <div className="drop-plus" onClick={() => addForm(true)}></div>
      
      {show 
      && <Dropdown className="drop-menu" setShow={setShow}>
          {dropType
              ?<>
                {form.id
                ?<h6 className="mb-4">Змінити заклад</h6>
                :<h6 className="mb-4">Додати заклад</h6>}
                <div className="d-flex gap-3 flex-column">
                    <div>
                    <label className="inputs-label">Назва закладу</label>
                    <input 
                        value={form.name}
                        onChange={changeForm}
                        className="inputs" 
                        name="name"
                        placeholder="" />
                        {errors.name && <div className='error mt-1'>{errors.name}</div>}
                    </div>
                    <button
                      className="button w-100"
                      type="text"
                      onClick={() => sendForm()}>
                      {form.id ? 'Змінити' : 'Додати'}
                    </button>

                  {form.id &&
                  <div
                    className="button _none w-100"
                    type="text"
                    onClick={() => delForm(form.id)}>
                    Видалити
                  </div>
                  }
                    
                </div>
              </>
              :<>
                  <h6 className="mb-3">Заклади</h6>
                  <div className="d-flex gap-1 flex-column dropdown-list">
                  {build.list &&
                      build.list.map((item,key)=>{
                          const checked = build.selected.id === item.id ? '_checked' : '';
                          return <div 
                              key={key} 
                              className={`drop-item ${checked}`} 
                              onClick={()=>select(item.id)}>
                              <div className="text-truncate">{item.name}</div>
                              <div className="drop-edit" onClick={(e)=>editForm(e,item.id)} ></div>
                          </div>
                      })
                  }
                  </div>
              </>
          }
        </Dropdown>
      }
    </div>
  );
};

export { MenuBuild };
